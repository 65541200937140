import React, {useEffect, useState} from "react";
import {withI18n} from "@lingui/react";
import {getLocaleFromPath} from "utils/i18n.js";
import Layout from "components/layout/layout";
import Videos from "../../components/Videos";
import {graphql, useStaticQuery} from "gatsby";

const IndexPage = ({location, data}) => {

    const query = useStaticQuery(graphql`
        query {
          site {
            siteMetadata {
              videoRest {
                endpoints {
                  liveList
                }
              }
            }
          }
        }
    `);

    var videosUrl = query.site.siteMetadata.videoRest.endpoints.liveList;

    const [videos, setVideos] = useState(null);
    useEffect(() => {
        fetch(videosUrl)
            .then(response => response.json())
            .then(result => {
                setVideos(result);
            })
            .catch(error => {
                console.log(error);
                setVideos([]);
            });
    }, [videosUrl]);

    const locale = getLocaleFromPath(location.pathname);

    return (!videos ? '' : (
        <Layout location={location}>
            <div className="MainContent">
                <Videos title="Live" locale={locale} items={videos.slice(0, 16)}/>
            </div>
        </Layout>
    ));
};

export default withI18n()(IndexPage);

